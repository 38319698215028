<template>
  <div class="d-flex align-center flex-wrap" style="gap: 20px">
    <v-card
      v-for="item in exisitingDocuments"
      :key="item._id"
      class="d-flex align-center"
      :height="85"
      outlined
      @click="viewDocument(item)"
    >
      <v-card-text>
        <div class="d-flex align-center" style="column-gap: 10px">
          <cz-icon :size="44" :src="mdiFilePdfBox" color="primary" />
          <div class="d-flex flex-column">
            <div class="text-body-1 font-weight-semibold textPrimary--text">
              {{ item.typeText }}
            </div>
            <div class="textPrimary--text text-subtitle-2" style="opacity: 0.5">
              {{
                $t('vendor.orderSubmissions.documentUploadedAt', [
                  formatDateTimeToDisplay(item.createdAt)
                ])
              }}
            </div>
          </div>

          <cz-button
            :icon-size="25"
            :icon-src="mdiOpenInNew"
            color="primary"
            class="mr-2"
            icon
          />
        </div>
      </v-card-text>
    </v-card>
    <v-card
      outlined
      :height="85"
      :min-width="130"
      hover
      @click="addDocumentDialog.show = true"
    >
      <v-card-text
        class="d-flex flex-column justify-center align-center"
        style="gap: 5px"
      >
        <cz-icon :src="mdiPlusCircleOutline" color="primary" :size="32" />
        <div class="text-subtitle-2 font-weight-semibold">
          {{ $t('vendor.orderSubmissions.addAddtionalDocument') }}
        </div>
      </v-card-text>
    </v-card>
    <cz-dialog v-if="addDocumentDialog.show" v-model="addDocumentDialog.show">
      <div class="d-flex flex-column" style="gap: 15px">
        <cz-form-field
          :label="$t('vendor.orderSubmissions.additionalDocumentFile')"
        >
          <cz-dropzone
            accept="application/pdf"
            @change="onAdditionalDocumentChange"
            :height="55"
            :title="$t('dropzone.title')"
          >
            <div slot="files" class="py-2 dropzone-files">
              <cz-chip dark color="secondary" circle-edges removeable small />
            </div>
          </cz-dropzone>
        </cz-form-field>
      </div>
    </cz-dialog>
  </div>
</template>

<script>
import {
  CzIcon,
  CzButton,
  CzDialog,
  CzChip,
  CzDropzone,
  CzFormField
} from '@/components';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { mdiFilePdfBox, mdiPlusCircleOutline, mdiOpenInNew } from '@mdi/js';
export default {
  name: 'SubmissionAdditionalDocuments',
  components: {
    CzIcon,
    CzButton,
    CzChip,
    CzDialog,
    CzDropzone,
    CzFormField
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    exisitingDocuments() {
      if (this.submission?.additionalDocuments?.length) {
        return this.submission.additionalDocuments;
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      mdiOpenInNew,
      mdiPlusCircleOutline,
      mdiFilePdfBox,
      submission: null,
      additionalDocuments: [],
      addDocumentDialog: {
        show: false
      }
    };
  },
  methods: {
    formatDateTimeToDisplay,
    onAdditionalDocumentChange(files, index) {
      this.additionalDocuments[index].file = files[0];
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!isEqual(this.submission, value)) {
          this.submission = cloneDeep(value);
        }
      }
    },
    submission: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
